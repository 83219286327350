import React from 'react';
import * as styles from './GetInTouch.module.scss';
import {Image} from '../../../hooks/gatsbyImg';

const GetInTouch = () => {
    return (
        <div className={styles.container}>
            <div className={styles.innerContainer}>
                <h2>Get in <span>Touch</span></h2>
                <p><span>Inquire about MASTER WiZR or share your thoughts with us.</span><br/> We can help you with your presentation material, digital storytelling, and communication needs.</p>
                <div className={styles.infos}>
                    <div className={styles.info} style={{color:'white',backgroundColor:'#EC7600'}}>
                        <a href='mailto:info@masterwizr.com'>
                        <p>Email us:</p>
                        <span>info@masterwizr.com</span>
                        </a>
                    </div>
                    <div className={styles.info}>
                        <p>Call us:</p>
                        <span>858 337 9303</span>
                    </div>
                </div>
                <p className={styles.location}>Our locations:</p>
                <p className={styles.location1}>San Diego, USA</p>
                <p className={styles.location1}>Norway</p>
            <Image filename='img-contact-form.png' className={styles.image} height='auto' />

            <div className={styles.contentWrapper}>
                <h1>Send us a message today</h1>
                <p>Inquire about MASTER WiZR. Beyond software subscription questions we can help you with your presentations, digital storytelling, and communication needs.</p>
            </div>
            </div>
        </div>
    );
};

export default GetInTouch;
