import React, { useEffect } from "react";
import Footer from "../components/atoms/footer";
import Header from "../components/atoms/header";
import HeroBanner from "../components/atoms/HeroBanner";
import ContactForm from "../components/ogranisms/ContactForm";
import FreeTrial from "../components/atoms/freeTrial";
import StyledButton from "../components/atoms/StyledButton";
import Head from "../components/ogranisms/head";
import { IoIosArrowForward } from "react-icons/io";
import AOS from "aos";
import "aos/dist/aos.css";
function Modules() {
    useEffect(() => {
        AOS.init({
          disable: 'mobile',
          duration : 1500
        });
      }, []);
    return (
        <>
        <Head
          title="Welcome to the WiZR world"
          description="Welcome to the WiZR world
          The place to organize, present, share and collaborate. Cultivating what matters the most to you."
          ogTitle="About us"
        />
        <main>
            <Header />
            <HeroBanner filename='13.1.jpg' extraClass="contactUs">
                <h3 data-aos="zoom-in" data-aos-delay="100">We are here for you</h3>
                <h1 data-aos="zoom-in" data-aos-delay="200">Contact Sales</h1>
                <div data-aos="flip-down"
                data-aos-delay="100">
                <StyledButton
                 extraClass="whiteBtn"
                 title="Let's talk" icon={<IoIosArrowForward />}
                 link="https://beta-studio.mwstream.com/control-panel/cover-pages"
                 email={true}
                 />
                 
                 </div>
                 </HeroBanner>
            <ContactForm />
            <FreeTrial />
            <Footer/>
        </main>
      </>
    );
}

export default Modules;
