import React from 'react';
import * as styles from './ContactForm.module.scss';
import Form from "../../molecules/Form";
import GetInTouch from "../../molecules/GetInTouch";

const ContactForm = () => {
    return (
        <div className={styles.container}>
            <div className={styles.innerContainer}>
                <GetInTouch />
                <Form />
            </div>
        </div>
    );
};

export default ContactForm;
